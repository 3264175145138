import {
  closeSidebar,
  openSidebar,
  sidebarClosed,
  getSize,
  setSize
} from '@/utils/app'

const state= {
  sidebar: {
    opened: sidebarClosed(),
    withoutAnimation: false
  },
  device: 'desktop',
  size: getSize() || 'mini',
  debug: {},
  dataColumns:[],
  originList:[]
};
const mutations= {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened ? closeSidebar() : openSidebar()
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    closeSidebar()
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  SET_SIZE: (state, size) => {
    state.size = size
    setSize(size)
  },
  SET_DEBUG: (state, debug) => {
    state.debug = debug
  },
  SET_OTHER_ORIGIN_DATA:(state, obj)=>{
    state.dataColumns = obj.dataColumns;
    state.originList = obj.list
  }
};
const actions= {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  setSize({ commit }, size) {
    commit('SET_SIZE', size)
  },
  setDebug({ commit }, debug) {
    commit('SET_DEBUG', debug)
  },
  setOtherOriginData({ commit }, obj) {
    commit('SET_OTHER_ORIGIN_DATA', obj)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};