import {
  loginByUsername as loginByUsernameApi,
  logout as logoutApi,
  getQRcode,
  loginDDing,
  moreLogin
} from "@/api/login";
import { userInfo } from "@/api/user";
import { getToken, removeToken } from "@/utils/auth";

const user = {
  state: {
    token: getToken(),
    name: undefined,
    roles: [],
    permissions: [],
    models: [],
    allModels: [],
    isDesign: undefined,
    shortLink: false,
    role_level: undefined,
    adminWhite: 0
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_ROLE_LEVEL: (state, role_level) => {
      state.role_level = role_level;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    },
    SET_MODELS: (state, models) => {
      state.models = models;
    },
    SET_ALL_MODELS: (state, models) => {
      state.allModels = models;
    },
    SET_IS_DESIGN: (state, models) => {
      state.isDesign = models;
    },
    SET_SHORT_LINK: (state, shortLink) => {
      state.shortLink = shortLink;
    },
    SET_ADMIN_WHITE: (state, adminWhite) => {
      state.adminWhite = adminWhite;
    }
  },
  actions: {
    // 悦玩账号密码登录
    loginByUsername( userInfo) {
      const username = userInfo.username.trim();
      return new Promise((resolve, reject) => {
        // prettier-ignore
        loginByUsernameApi(username, userInfo.password).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      });
    },
    // 获取登录二维码链接
    getByQRCode(codeState) {
      return new Promise((resolve, reject) => {
        // prettier-ignore
        getQRcode(codeState).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      });
    },
    // 登录中间页回传code和state
    loginByCodeState( codeState) {
      return new Promise((resolve, reject) => {
        // prettier-ignore
        loginDDing(codeState).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      });
    },
    // 多账号回传信息
    loginByMoreAccount( codeState) {
      return new Promise((resolve, reject) => {
        // prettier-ignore
        moreLogin(codeState).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      });
    },
    getUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        // prettier-ignore
        userInfo().then(response => {
          commit('SET_NAME', response.name)
          commit('SET_ROLE_LEVEL',response.role_level)
          commit('SET_ROLES', response.roles)
          commit('SET_PERMISSIONS', response.permissions)
          commit('SET_MODELS', response.models)
          commit('SET_ALL_MODELS', response.all_models)
          commit('SET_IS_DESIGN',response.is_design)
          commit('SET_SHORT_LINK',response.short_link)
          commit('SET_ADMIN_WHITE',response.admin_white)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      });
    },
    /**
     * 前端退出登录
     * @param {*} param0
     */
    fedLogout({ commit }) {
      return new Promise(resolve => {
        commit("SET_TOKEN", "");
        removeToken();
        resolve();
      });
    },
    /**
     * 退出登录
     * @param {*} param0
     */
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        // prettier-ignore
        logoutApi().then(response => {
          commit('SET_TOKEN', '')
          commit('SET_NAME', undefined)
          commit('SET_ROLE_LEVEL',undefined)
          commit('SET_ROLES', [])
          commit('SET_PERMISSIONS', [])
          removeToken()
          resolve(response)
        }).catch(err => {
          reject(err)
        })
      });
    }
  }
};

export default user;
