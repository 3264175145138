import Cookies from 'js-cookie'

const SidebarStatus = 'App-Sidebar-Status'

/**
 * 得到应用侧边栏状态
 */
export function getSidebarStatus() {
  return Cookies.get(SidebarStatus)
}

/**
 * 侧边栏是否打开
 */
export function sidebarOpened() {
  return getSidebarStatus() === 1
}

export function sidebarClosed() {
  return getSidebarStatus() === 0
}

/**
 * 设置侧边栏状态
 * @param {string} status
 */
export function setSidebarStatus(status) {
  Cookies.set(SidebarStatus, status)
}

/**
 * 打开侧边栏
 */
export function openSidebar() {
  setSidebarStatus(1)
}

/**
 * 关闭侧边栏
 */
export function closeSidebar() {
  setSidebarStatus(0)
}

const sizeKey = 'App-size'

/**
 * 设置 size 大小
 * @param {string} size
 */
export function setSize(size) {
  Cookies.set(sizeKey, size)
}

/**
 * 得到 Size 大小
 */
export function getSize() {
  return Cookies.get(sizeKey)
}
