import Cookies from 'js-cookie'

const TokenKey = 'Auth-Token'

/**
 * 得到用户登录后的 Token
 */
export function getToken() {
  return Cookies.get(TokenKey)
}

/**
 * 设置 Token
 * @param {String} token
 */
export function setToken(token) {
  if (!token) {
    return
  }
  Cookies.set(TokenKey, token)
}

/**
 * 删除登录用户的 Token
 */
export function removeToken() {
  Cookies.remove(TokenKey)
}
