import request from '@/utils/request'

/**
 * 得到用户信息
 */
export function userInfo() {
  return request.get('v1/user/info')
}

/**
 * 修改密码
 * @param {string} oldPwd 原密码
 * @param {string} newPwd 新密码
 * @param {string} conPwd 确认密码
 */
export function modifyPwd(oldPwd, newPwd, conPwd) {
  return request.post('v1/user/password/modify', {
    password: oldPwd,
    password_new: newPwd,
    password_confirm: conPwd
  })
}

//Get Account Binding DDing
export function fetchBindInfo(query) {
  return request.post('v1/admin/getDingbindDetail', query)
}

//Get DingDing QR Code
export function getQRcode(query) {
  return request.get('v1/getRedirectUri', { params: query })
}

//Binding DingDing Account
export function bindDDing(query) {
  return request.post('v1/admin/bindDingToSelf', query)
}