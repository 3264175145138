// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import axios from 'axios';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css';
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/index.scss';
import App from './App';
import router from './router';
import store from './store';
import {renderChart} from '@/utils/index';
import VCharts from 'v-charts';
// import './icons';
// import './permission';
// import './errorLog';
// import * as SelfComponents from '@/views/components';
// 可视化组件
// import VeLine from 'v-charts/lib/line.common';

// 常用组件全局注册
// for (const key of Object.keys(SelfComponents)) {
//   Vue.component(
//     key
//       .match(/[A-Z]+[^A-Z]*/g)
//       .map((item) => item.toLowerCase())
//       .join('-'),
//     SelfComponents[key],
//   );
// }
// 可视化组件全局注册
// [VeLine].forEach((comp) => {
//   Vue.component(comp.name, comp);
// });

// 获取远观后台管理中的data
window.addEventListener("message", async(event) => {
  // const data = [[
  //   {name:'objectId',data:['djjsd','ufufu','kfkffkkf','clclcl','pcpcpcpcp']},
  //   {name:'register_total',data:[1,5,6,9,8]},
  //   {name:'day-1',data:[8,9,8,5,3]},
  //   {name:'day-2',data:[5,6,9,6,3]},
  //   {name:'day-3',data:[8,9,8,5,3]},
  //   {name:'day-4',data:[5,6,9,6,3]},
  //   {name:'day-5',data:[8,9,8,5,3]},
  //   {name:'day-6',data:[5,6,9,6,3]}
  // ]]
  // let obj = renderChart(data);
  // await store.dispatch('app/setOtherOriginData', obj);
  if (event.origin == "https://app.guandata.com") {
    let obj = renderChart(event.data);
    await store.dispatch('app/setOtherOriginData', obj);
  }
});

/**
 * 得到用户登录后的 Token
 */
 export function getFixedToken() {
  let token = localStorage.getItem('Auth-Token');
  if (!token) {
    axios
      .post('http://tg.hzyuewan.com/admin/v1/login', {
        username: 'ludan',
        password: 'ludan123456'
      })
      .then(function (response) {
        localStorage.setItem('Auth-Token', response.headers.authorization);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  return token;
}
window.onload = function () {
  getFixedToken();
};

Vue.use(Element, {
  size: Cookies.get('size') || 'mini',
});

// Vue.component(VeLine.name, VeLine)
Vue.use(VCharts);

Vue.prototype.$vue = Vue;

Vue.config.productionTip = false;
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App),
});
