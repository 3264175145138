import request from '@/utils/request'

/**
 * 根据用用户名登录
 * @param {string} username 用户名
 * @param {string} password 密码
 */
export function loginByUsername(username, password) {
  return request.post('v1/login', {
    username,
    password
  })
}

//Get DingDing QR Code
export function getQRcode(query) {
  return request.get('v1/getRedirectUri', { params: query })
}

//DingDing Back Info
export function loginDDing(query) {
  return request.post('v1/getUserDingInfo', query)
}

//DingDing Back Info
export function moreLogin(query) {
  return request.post('v1/selectUsernameLogin', query)
}

/**
 * 退出登录
 */
export function logout() {
  return request.post('v1/logout')
}
