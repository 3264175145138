import { asyncRouterMap, constantRouterMap } from '@/router'

/**
 * 通过meta.role判断是否与当前用户权限匹配
 * @param roles
 * @param route
 */
function hasPermission(permissions, route) {
  let result = true

  if (route.children) {
    return result
  }

  if (route.meta && route.meta.permission) {
    result = false
    for (const permission of permissions) {
      if (permission.name && permission.name === route.meta.permission) {
        result = true
        return result
      }
    }
  }

  return result
}

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param routes asyncRouterMap
 * @param roles
 */
function filterAsyncRouter(routes, permissions) {
  const res = []
  routes.forEach(route => {
    const tmp = { ...route }

    if (hasPermission(permissions, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRouter(tmp.children, permissions)
        if (tmp.children.length !== 0) {
          res.push(tmp)
        }
      } else {
        res.push(tmp)
      }
    }
  })
  return res
}

const permission = {
  state: {
    routers: constantRouterMap,
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
      state.routers = constantRouterMap.concat(routers)
    }
  },
  actions: {
    generateRoutes({ commit }, data) {
      return new Promise(resolve => {
        const roles = data.roles
        const permissions = data.permissions
        let accessedRouters
        if (roles.indexOf('*') > -1 || permissions.indexOf('*') > -1) {
          accessedRouters = asyncRouterMap
        } else {
          accessedRouters = filterAsyncRouter(asyncRouterMap, permissions)
        }
        commit('SET_ROUTERS', accessedRouters)
        resolve()
      })
    }
  }
}

export default permission
