import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'

const service = axios.create({
  baseURL: 'http://tg.hzyuewan.com/admin/'
})

service.interceptors.request.use(
  config => {
    config.headers['Accept'] = 'application/json';
    config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('Auth-Token');
    // if (store.getters.token) {
    //   config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('Auth-Token');
    // }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    if (response.headers.authorization) {
      store.commit('SET_TOKEN', response.headers.authorization)
      localStorage.setItem('Auth-Token','response.headers.authorization')
    }

    if (response.data.debug) {
      store.commit('SET_DEBUG', response.data.debug)
    }

    // 修改操作 成功提示
    if (response.data.code === 0) {
      if (response.data.message && response.data.message !== 'OK') {
        Message({
          message: response.data.message,
          type: 'success',
          duration: 5 * 1000
        })
      }
    }

    if (response.data.code !== 0) {
      Message({
        message: response.data.message,
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(response.data)
    }
    return Promise.resolve(response.data.data)
  },
  error => {
    let message = error.message
    switch (error.response.status) {
      case 401:
        message = '用户未登录或登录信息已失效'
        localStorage.removeItem('Auth-Token');
        break;
      case 422:
        message = ''
        if (error.response.data && error.response.data.errors) {
          for (const key in error.response.data.errors) {
            const errors = error.response.data.errors[key]
            for (const index in errors) {
              message += errors[index] + '<br>'
            }
          }
        }
        break;
      default:
        break;
    }
    Message({
      message: message,
      dangerouslyUseHTMLString: true,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
