/**
 * 作用：该中间键主要存储用户在批量计划第一次使用的部门选项数据，为后面使用做历史记录填充
 */
const channelHistory = {
  namespaced: true,
  state: {
    ttDepart: [], // 头条批量计划部门
    ttChannel: undefined, // 头条批量计划渠道
    ttHost: undefined, // 头条批量计划域名
  },
  mutations: {
    SET_TT_DEPART: (state, depart) => {
      state.ttDepart = depart;
    },
    SET_TT_CHANNEL: (state, channel) => {
      state.ttChannel = channel;
    },
    SET_TT_HOST: (state, host) => {
      state.ttHost = host;
    },
  },
};

export default channelHistory;
