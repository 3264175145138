const getters = {
  sidebar: (state) => state.app.sidebar,
  size: (state) => state.app.size,
  device: (state) => state.app.device,
  visitedViews: (state) => state.tagsView.visitedViews,
  cachedViews: (state) => state.tagsView.cachedViews,
  token: (state) => state.user.token,
  name: (state) => state.user.name,
  roles: (state) => state.user.roles,
  role_level: (state) => state.user.role_level,
  permissions: (state) => state.user.permissions,
  models: (state) => state.user.models,
  allModels: (state) => state.user.allModels,
  isDesign: (state) => state.user.isDesign,
  shortLink: (state) => state.user.shortLink,
  adminWhite: (state) => state.user.adminWhite,
  permission_routers: (state) => state.permission.routers,
  addRouters: (state) => state.permission.addRouters,
  errorLogs: (state) => state.errorLog.logs,
  debug: (state) => state.app.debug,
  // 渠道批量计划使用历史记录
  ttDepart: (state) => state.channelHistory.ttDepart,
  ttChannel: (state) => state.channelHistory.ttChannel,
  ttHost: (state) => state.channelHistory.ttHost,
};

export default getters;
