import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// const NODE_ENV = process.env.NODE_ENV;
const routes = [
  {
    path: '/',
    name: '/',
    component:() => import(/* webpackChunkName: "about" */ '../views/ks/index.vue'),
    // children:[
    //   {
    //     path:'ks',
    //     name:'ks',
    //     component:() => import(/* webpackChunkName: "about" */ '../views/ks/index.vue'),
    //     meta: { title: '测试', noCache: true },
    //   }
    // ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
